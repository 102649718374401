import { ToolTypesProps } from '@3plearning/question-components-library';
import {
    Button, Text
} from '@chakra-ui/react';
import React, {
    FC, useEffect, useRef
} from 'react';
import { useDispatch } from 'react-redux';

import AppImage from '../../components/app-image/app-image';
import {
    setMeasurementToolStatus, setMeasurementToolCoord
} from '../question-container/store/question-actions';
import { coordOffset } from './measurement-tools.constants';
import {
    IconProps, MeasureBtnProps
} from './measurement-tools.types';

export const MeasureBtn: FC<MeasureBtnProps> = ({
    attrs, isActive
}) => {
    const btnRef = useRef<HTMLButtonElement>(null);
    const dispatch = useDispatch();

    useEffect(() => {
        setTimeout(() => {
            const btn = btnRef.current?.getBoundingClientRect();

            dispatch(setMeasurementToolCoord({
                tool: attrs?.type,
                coord: {
                    x: btn?.x as number + coordOffset[attrs?.type as ToolTypesProps].x,
                    y: btn?.bottom as number + coordOffset[attrs?.type as ToolTypesProps].y
                }
            }));
        }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Button
            ref={btnRef}
            data-testid={'hint-button'}
            size={'sm'}
            variant={'hint'}
            tabIndex={-1}
            isActive={isActive}
            onClick={() => dispatch(setMeasurementToolStatus(attrs?.type as ToolTypesProps))}
            _active={{
                boxShadow: 'none',
                bg: 'primary.50'
            }}
            _focus={{ boxShadow: 'none' }}
        >
            <AppImage imageType={'svg'} iconType={attrs?.icon as IconProps} />
            <Text
                ml={'10px'}
                as={'span'}
                color={'primary.600'}
            >
                {attrs?.label}
            </Text>
        </Button>
    );
};
