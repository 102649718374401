import {
    Content,
    RadioSegment,
    DropdownSegment,
    CheckboxGroupSegment,
    ToolTypesProps
} from '@3plearning/question-components-library';

import {
    QuestionProgressState, QuestionSetType, STUDENT_STATES
} from '../../../helpers/enums';
import { isDefined } from '../../../helpers/utils';
import {
    ActivityState, Activity, Progress
} from '../sub-components/question-activity/question-activity.types';
import {
    GET_QUESTION_FAILURE,
    GET_QUESTION_REQUESTED,
    GET_QUESTION_SUCCESS,
    GET_QUESTIONS_DETAILS_FAILURE,
    GET_QUESTIONS_DETAILS_REQUESTED,
    GET_QUESTIONS_DETAILS_SUCCESS,
    GET_STRAND_UOW_FAILURE,
    GET_STRAND_UOW_REQUESTED,
    GET_STRAND_UOW_SUCCESS,
    LOAD_NEXT_QUESTION,
    QuestionActions,
    RESET_ACTIVITY,
    SAVE_QUESTION_FAILURE,
    SAVE_QUESTION_REQUESTED,
    SAVE_QUESTION_SUCCESS,
    SET_STUDENT_STATE,
    SET_MILESTONE_COMPLETE,
    DISMISS_ERROR,
    IS_OFFLINE,
    IS_WORKED_SOLUTION_ACTIVATED,
    SET_READ_ALOUD_ENABLED,
    SET_READ_ALOUD_LOCALE,
    SET_PLAY_CORRECT_STARS,
    SET_QUESTION_PROGRESS_STATE,
    SET_SERVER_ERROR_INTERCEPTED,
    SET_MEASUREMENT_TOOL_STATUS,
    SET_MEASUREMENT_TOOL_COORD,
    SET_MEASUREMENT_TOOLS
} from './question-action-types';

export const INITIAL_QUESTION_STATE: ActivityState = {
    questName: undefined,
    strandName: undefined,
    unitOfWork: undefined,
    keyboardPreset: undefined,
    learningIntention: undefined,
    successCriteria: undefined,
    numQuestionSteps: 0,
    milestoneComplete: undefined,
    progress: undefined,
    activity: undefined,
    nextQuestion: undefined,
    updatedProgress: undefined,
    validation: undefined,
    questionSaveInProgress: false,
    isError: false,
    serverErrorIntercepted: undefined,
    isLoading: false,
    isOffline: false,
    isSuccess: false,
    studentState: STUDENT_STATES.progress,
    studentName: undefined,
    questionExample: undefined,
    conceptRefresh: undefined,
    questionSetType: undefined,
    praiseFeedback: undefined,
    isWorkedSolutionActivated: false,
    readAloudEnabled: true,
    readAloudLocale: 'en-AU',
    measurementToolsStatus: {
        ruler: false,
        protractor: false,
        image: false
    },
    measurementToolsInitialCoords: {
        ruler: {
            x: 0,
            y: 0
        },
        protractor: {
            x: 0,
            y: 0
        },
        image: {
            x: 0,
            y: 0
        }
    },
    measurementTools: undefined,
    playCorrectStars: undefined,
    questionProgressState: QuestionProgressState.notCurrent,
    questionsCount: 0
};

export const activityReducer = (state: ActivityState = INITIAL_QUESTION_STATE, action: QuestionActions): ActivityState => {
    switch (action.type) {
    case GET_QUESTIONS_DETAILS_REQUESTED:
        return {
            ...state,
            isLoading: true
        };
    case GET_QUESTIONS_DETAILS_SUCCESS: {
        const {
            questName, learningIntention, successCriteria, studentName,
            questionExample, conceptRefresh, praiseFeedback, questionSetType, keyboardPreset
        } = action.payload.response;

        return {
            ...state,
            questName,
            learningIntention,
            successCriteria,
            isLoading: false,
            studentName,
            questionExample,
            conceptRefresh,
            praiseFeedback,
            keyboardPreset,
            questionSetType,
            questionsCount: questionSetType === QuestionSetType.unitTest ? 20 : 0
        };
    }
    case GET_QUESTIONS_DETAILS_FAILURE:
        return {
            ...state,
            isError: true,
            isLoading: false
        };
    case GET_STRAND_UOW_REQUESTED:
        return {
            ...state,
            isLoading: true
        };
    case GET_STRAND_UOW_SUCCESS: {
        const {
            strand,
            uow
        } = action.payload.response;

        return {
            ...state,
            strandName: strand,
            unitOfWork: uow
        };
    }
    case GET_STRAND_UOW_FAILURE:
        return {
            ...state,
            isError: false,
            isLoading: false
        };
    case GET_QUESTION_REQUESTED:
        return {
            ...state,
            isLoading: true
        };
    case GET_QUESTION_SUCCESS: {
        const {
            nextQuestion, progress
        } = action.payload.response;
        const nextQuestionWithRandomisedLists = randomiseQuestionLists(nextQuestion?.question);

        if (isDefined(nextQuestion)) {
            nextQuestion.question = nextQuestionWithRandomisedLists;
        }

        let studentState = state.studentState;
        let numQuestionSteps = 0;

        if (progress) {
            studentState = getStudentState(progress);
            const flatTotals: number[][] = progress.points?.map((section) => section.stars.flat()) || [];

            numQuestionSteps = flatTotals.flat().length;
        }

        return {
            ...state,
            progress: { ...progress },
            activity: {
                ...nextQuestion,
                contentId: nextQuestion?.contentId || nextQuestion?.questionVariantId,
                questionVariantId: nextQuestion?.questionVariantId || nextQuestion?.contentId
            },
            isSuccess: true,
            studentState,
            isLoading: false,
            numQuestionSteps
        };
    }
    case GET_QUESTION_FAILURE:
        return {
            ...state,
            isError: true,
            isLoading: false
        };
    case SAVE_QUESTION_REQUESTED: {
        return {
            ...state,
            questionSaveInProgress: true,
            isLoading: true
        };
    }
    case SAVE_QUESTION_SUCCESS: {
        const {
            nextQuestion, progress, validation
        } = action.payload.response;
        const nextQuestionWithRandomisedLists = randomiseQuestionLists(nextQuestion?.question);

        if (isDefined(nextQuestion)) {
            nextQuestion.question = nextQuestionWithRandomisedLists;
        }

        let questionProgressState;

        if(isDefined(validation)) {
            questionProgressState = validation.isCorrect ?
                QuestionProgressState.correctAnswered : QuestionProgressState.incorrectAnswered;
        } else {
            questionProgressState = QuestionProgressState.notCurrent;
        }

        return {
            ...state,
            updatedProgress: { ...progress },
            nextQuestion: {
                ...nextQuestion,
                contentId: nextQuestion?.contentId || nextQuestion?.questionVariantId,
                questionVariantId: nextQuestion?.questionVariantId || nextQuestion?.contentId
            },
            ...(validation && {
                validation: {
                    ...validation,
                    workedSolution: validation.workedSolution?.filter((solution) => solution)
                }
            }),
            questionSaveInProgress: false,
            isLoading: false,
            milestoneComplete: false,
            questionProgressState
        };
    }
    case SAVE_QUESTION_FAILURE: {
        return {
            ...state,
            questionSaveInProgress: false,
            isError: true,
            isLoading: false
        };
    }
    case LOAD_NEXT_QUESTION: {
        const nextQuestion = state.nextQuestion;
        const updatedProgress = state.updatedProgress;

        return {
            ...state,
            activity: { ...nextQuestion },
            nextQuestion: undefined,
            progress: { ...updatedProgress },
            updatedProgress: undefined,
            validation: undefined,
            milestoneComplete: false,
            questionProgressState: QuestionProgressState.nextUnanswered
        };
    }
    case RESET_ACTIVITY: {
        const activity: Activity | undefined = state.activity;

        return {
            ...state,
            activity: {
                ...activity,
                contentId: undefined,
                questionVariantId: undefined
            },
            milestoneComplete: false,
            playCorrectStars: undefined
        };
    }
    case SET_STUDENT_STATE:
        return {
            ...state,
            studentState: action.studentState
        };
    case SET_MILESTONE_COMPLETE:
        return {
            ...state,
            milestoneComplete: action.milestoneComplete
        };
    case DISMISS_ERROR:
        return {
            ...state,
            isError: false
        };
    case IS_OFFLINE:
        return {
            ...state,
            isOffline: action.isOffline
        };
    case SET_READ_ALOUD_ENABLED:
        return {
            ...state,
            readAloudEnabled: action.readAloudEnabled
        };
    case SET_READ_ALOUD_LOCALE:
        return {
            ...state,
            readAloudLocale: action.readAloudLocale
        };
    case SET_MEASUREMENT_TOOLS:
        return {
            ...state,
            measurementTools: action.tools
        };
    case SET_MEASUREMENT_TOOL_STATUS: {
        const status = { ...state.measurementToolsStatus };

        status[action.tool as ToolTypesProps] = !state.measurementToolsStatus[action.tool as ToolTypesProps];

        return {
            ...state,
            measurementToolsStatus: status
        };
    }
    case SET_MEASUREMENT_TOOL_COORD: {
        const initialCoords = { ...state.measurementToolsInitialCoords };

        initialCoords[action.payload.tool as ToolTypesProps] = action.payload.coord;

        return {
            ...state,
            measurementToolsInitialCoords: initialCoords
        };
    }
    case IS_WORKED_SOLUTION_ACTIVATED:
        return {
            ...state,
            isWorkedSolutionActivated: action.workedSolution
        };
    case SET_PLAY_CORRECT_STARS:
        return {
            ...state,
            playCorrectStars: true
        };
    case SET_QUESTION_PROGRESS_STATE:
        return {
            ...state,
            questionProgressState: action.questionProgressState
        };
    case SET_SERVER_ERROR_INTERCEPTED: {
        return {
            ...state,
            serverErrorIntercepted: action.payload
        };
    }
    }

    return state;
};

function getStudentState (progress: Progress) {
    const{
        intro, question, summary
    } = STUDENT_STATES;
    const {
        attempt, stepsCompleted, isQuestCompleted
    } = progress;

    if (isQuestCompleted) {
        return summary;
    } else if (attempt === 1 && stepsCompleted === 0) {
        return intro;
    } else {
        return question;
    }
}

/* istanbul ignore next */
function randomiseQuestionLists (question: Content | undefined) {
    if (!question) return;

    const copiedQuestion = question.slice();

    copiedQuestion.forEach(questionContent => {
        if (
            (
                questionContent.type === 'radioGroup'
                || questionContent.type === 'check-box-group'
                || questionContent.type === 'dropdown'
            )
            && ((questionContent.attrs as (RadioSegment | DropdownSegment | CheckboxGroupSegment)['attrs']).randomise)
        ) {
            shuffleArray(questionContent.content as Content);
            (questionContent.attrs as (RadioSegment | DropdownSegment | CheckboxGroupSegment)['attrs']).randomise = false;
        }

        if (Array.isArray(questionContent.content)) {
            randomiseQuestionLists(questionContent.content as Content);
        }
    });

    return copiedQuestion;
}

function shuffleArray (array: Content) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));

        [array[i], array[j]] = [array[j], array[i]];
    }
}
