import {
    MeasurementToolsProps, ToolTypesProps
} from '@3plearning/question-components-library';
import React, { useEffect } from 'react';
import {
    useDispatch, useSelector
} from 'react-redux';

import { AppState } from '../../app/store/store.types';
import { setMeasurementTools } from '../question-container/store/question-actions';
import { useBtnsAttrs } from './hooks/use-btns-attrs';
import { MeasureBtn } from './measure-btn';
import { simplifyToolsData } from './measurement-tools.utils';

export const MeasurementTools = () => {
    const dispatch = useDispatch();
    const activityState = useSelector<AppState, AppState['activityState']>(state => state.activityState);
    const {
        measurementTools, measurementToolsStatus, activity
    } = activityState;
    const { getBtn } = useBtnsAttrs();

    useEffect(() => {
        if (activity?.measurementTool) {
            dispatch(setMeasurementTools(simplifyToolsData(activity?.measurementTool) as MeasurementToolsProps[]));
        } else if (activity?.measurementTool === null) {
            dispatch(setMeasurementTools(undefined));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activity]);

    return (
        <>
            {
                measurementTools?.map((tool) => {
                    const attrs = getBtn(tool.type);

                    return (
                        <MeasureBtn
                            key={attrs?.type}
                            attrs={attrs}
                            isActive={measurementToolsStatus[attrs?.type as ToolTypesProps]}
                        />
                    );
                })
            }
        </>
    );
};
