import { answerRecord } from '@3plearning/question-components-library';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import {
    getQuestionSuccess, getQuestionFailure, saveQuestionSuccess, getQuestionsDetailsSuccess
} from '../../containers/question-container/store/question-actions';
import { Resources } from '../../resources/resources';
import {
    progress,
    template,
    questionItem
} from './single-variant.constants';

export const useSingleVariant = () => {
    const dispatch = useDispatch();
    const [variantDetails, setVariantDetails] = useState({
        id: '',
        questionItem
    });
    const getSingleVariant = async (variantId: string, questionSetType: string | undefined) => {
        if (questionSetType) {
            dispatch(getQuestionsDetailsSuccess({ response: { questionSetType: Number(questionSetType) } }));
        }
        try {
            const response = await Resources.questionVariantsFromContentApi.get({ variantId }).send();

            if (response.data) {
                template.response.nextQuestion.questionVariantId = response.data.id;
                template.response.nextQuestion.question = response.data.questionItem.questionContent.content;
                template.response.nextQuestion.measurementTool = response.data.questionItem.measurementTool?.content;
                template.response.nextQuestion.hint = response.data.questionItem.hintContent
                    ? response.data.questionItem.hintContent.content
                    : [];
                dispatch(getQuestionSuccess(template));
                setVariantDetails(response.data);
            } else {
                dispatch(getQuestionFailure());
            }
        } catch (e) {
            dispatch(getQuestionFailure());
        }
    };
    const validateSingleVariant = async (studentAnswers: answerRecord) => {
        const workedSolution = () => {
            if (!variantDetails?.questionItem.workedSolution) {
                return [];
            } else if (variantDetails?.questionItem.workedSolution.length) {
                return variantDetails?.questionItem.workedSolution;
            }
        };

        try {
            const response = await Resources.testSympy.post().withBody({
                studentAnswers,
                validAnswers: {
                    questionVariantId: variantDetails?.id,
                    answers: variantDetails?.questionItem.answerContent,
                    workedSolution: workedSolution()
                }
            }).send();

            if (response.data) {
                dispatch(saveQuestionSuccess({
                    response: {
                        validation: response.data,
                        nextQuestion: {
                            questionVariantId: variantDetails.id,
                            question: variantDetails.questionItem.questionContent.content,
                            hint: variantDetails.questionItem.hintContent
                                ? variantDetails.questionItem.hintContent.content
                                : []
                        },
                        progress
                    }
                }));
            } else {
                dispatch(getQuestionFailure());
            }
        } catch (e) {
            dispatch(getQuestionFailure());
        }
    };

    return {
        getSingleVariant,
        validateSingleVariant
    };
};
