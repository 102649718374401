export const coordOffset = {
    ruler: {
        x: -400,
        y: 80
    },
    protractor: {
        x: -400,
        y: 80
    },
    image: {
        x: 0,
        y: 0
    }
};
