export const useBtnsAttrs = () => {
    const btns = [
        {
            type: 'ruler',
            label: 'Ruler',
            icon: 'iconMeasureRuler'
        },
        {
            type: 'protractor',
            label: 'Protractor',
            icon: 'iconMeasureProtractor'
        }
    ];
    const getBtn = (type: string) => btns.find((btn) => btn.type === type);

    return { getBtn };
};
