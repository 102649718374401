import {
    Content, ContentBlock, MeasurementToolsProps
} from '@3plearning/question-components-library';

export const simplifyToolsData = (tools: Content) => {
    const arr: MeasurementToolsProps[] = [];

    tools.forEach((tool) => {
        if (typeof tool === 'object' && 'content' in tool) {
            arr.push((tool?.content?.[0] as ContentBlock)?.attrs as MeasurementToolsProps);
        }
    });

    if (arr.length === 0) {
        return undefined;
    }

    return arr;
};
