export const progress = {
    attempt: 1,
    stepsCompleted: 1,
    isQuestCompleted: false,
    points: [
        {
            sectionName: 'Fluency',
            pointsPerQuestion: 12,
            stars: [
                0,
                0,
                0,
                0,
                0
            ]
        },
        {
            sectionName: 'Reasoning',
            pointsPerQuestion: 20,
            stars: [
                0,
                0,
                0
            ]
        },
        {
            sectionName: 'ProblemSolving',
            pointsPerQuestion: 40,
            stars: [
                0,
                0
            ]
        }
    ],
    completedAttempts: 1,
    totalCoinsCount: 0,
    isCoinsCollected: false
};

export const template = {
    response: {
        nextQuestion: {
            questionVariantId: '',
            question: [],
            measurementTool: [],
            hint: []
        },
        progress
    }
};

export const questionItem = {
    id: '',
    answerContent: [],
    workedSolution: [],
    questionContent: { content: [] },
    hintContent: { content: [] }
};
